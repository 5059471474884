import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Values, ValuesType } from '../../shared/types';
import { isDevelopment } from '../../shared/utils';
import { useStyles } from './summary.styles';
import { defaultConfig } from '../../shared/consts/config.const';

type Props = {
  price: number;
  setValues: (value: Partial<Values>) => void;
  values: Values;
  isBlackFriday: () => boolean;
};

export const Summary = ({ price, setValues, values, isBlackFriday }: Props) => {
  const classes = useStyles();
  const { annually, currency, traffic: trafficConfig, contact } = defaultConfig;
  const { legalProtection, traffic } = values;
  const [contactRequired, setContactRequired] = useState(false);
  const handleAnnuallyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ [ValuesType.ANNUALLY]: event.target.checked });
  };
  const handleCurrencyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ [ValuesType.CURRENCY]: parseFloat(event.target.value) });
  };
  const _handleClick = () => {
    const selectedCurrency = defaultConfig.currency.variants.find(
      (variant) => variant.value === values.currency,
    );

    if (selectedCurrency) {
      values['currencyName'] = selectedCurrency.name;
    }

    const selectedRetention = defaultConfig.retention.variants.find(
      (variant) => variant.value === values.retention,
    );

    if (selectedRetention) {
      values['retentionInterval'] = selectedRetention.interval;
    }

    const params = Object.entries(values)
      .map(([name, value]) => `${name}=${value}`)
      .join('&');
    const url = isDevelopment() ? 'http://app.cux.lo' : 'https://app.cux.io';

    window.open(`${url}/register?${params}&price=${price}`, '_blank');
  };

  useEffect(() => {
    setContactRequired(legalProtection || traffic === trafficConfig.max);
  }, [legalProtection, traffic, trafficConfig.max]);

  return (
    <>
      {contactRequired && (
        <Button
          className={classes.button}
          color="primary"
          href={contact.url}
          target="_blank"
          variant="contained"
        >
          Talk to us
        </Button>
      )}

      {!contactRequired && (
        <>
          <Typography className={classes.title}>{currency.title}</Typography>
          <div className={classes.row}>
            <Typography className={classes.price}>
              {price.toFixed(2)}
            </Typography>
            <Select
              className={classes.selectWrapper}
              onChange={handleCurrencyChange}
              value={values.currency}
              disableUnderline
              classes={{
                select: classes.select,
              }}
            >
              {currency.variants.map(({ label, value }, index) => (
                <MenuItem key={`menu-item-${index}`} value={value as number}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <FormControlLabel
            className={classes.switcher}
            classes={{
              label: classes.switcherLabel,
            }}
            control={
              <Switch
                onChange={handleAnnuallyChange}
                size="small"
                checked={values.annually}
              />
            }
            label={annually.label}
          />
          {isBlackFriday() && values.annually && (
            <Typography className={classes.promo}>
              Black Friday PROMO -40% applied
            </Typography>
          )}
          <Button
            className={classes.button}
            color="primary"
            onClick={_handleClick}
            size="small"
            variant="contained"
          >
            Create an account
          </Button>
        </>
      )}
    </>
  );
};
