import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import React from 'react';
import { Config, Values } from '../../shared/types';
import { useStyles } from './traffic-slider.styles';

type Props = {
  config: Config['traffic'];
  calculateScale: (value: number) => number;
  setValue: (values: Partial<Values>) => void;
  value: number;
};

type LabelProps = {
  open: boolean;
  value: number;
  children: JSX.Element;
};

const CustomLabel = ({ children, open, value }: LabelProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      open={open}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
};

export const TrafficSlider = ({
  config,
  calculateScale,
  setValue,
  value,
}: Props) => {
  const classes = useStyles();
  const { max, min, step } = config;
  const handleChange = (event: unknown, newValue: number) => {
    setValue({ traffic: newValue as number });
  };
  const getLabelFormat = (value: number) => {
    let title = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    if (min === value) {
      title = `Up to ${title}`;
    }

    if (max === value - 30600000) {
      title = `${title} and more`;
    }

    return `${title} pageviews / month`;
  };

  return (
    <Slider
      className={classes.slider}
      max={max}
      min={min}
      onChange={handleChange}
      scale={calculateScale}
      step={step}
      value={value}
      ValueLabelComponent={CustomLabel}
      valueLabelDisplay="on"
      valueLabelFormat={getLabelFormat}
    />
  );
};
