export enum Environment {
  DEVELOPMENT = 'development',
}

/**
 * Check what is current environment
 * @param {Environment} environment
 * @returns {boolean}
 */
const isEnvironment = (environment: Environment): boolean =>
  process.env.NODE_ENV === environment;

/**
 * Check if environment is development
 * @returns {boolean}
 */
export const isDevelopment = (): boolean =>
  isEnvironment(Environment.DEVELOPMENT);
