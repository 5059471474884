export enum ValuesType {
  ANNUALLY = 'annually',
  CURRENCY = 'currency',
  LEGAL_PROTECTION = 'legalProtection',
  RETENTION = 'retention',
  TRAFFIC = 'traffic',
}

export type Values = {
  [ValuesType.ANNUALLY]: boolean;
  [ValuesType.CURRENCY]: number;
  [ValuesType.LEGAL_PROTECTION]: boolean;
  [ValuesType.RETENTION]: number;
  [ValuesType.TRAFFIC]: number;
};
