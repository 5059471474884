import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { getSteps } from './calculator.model';
import { useStyles } from './calculator.styles';
import { Summary } from './components';
import { defaultConfig } from './shared/consts/config.const';
import { Values } from './shared/types';

export const Calculator = () => {
  const classes = useStyles();
  const {
    annually: annuallyConfig,
    currency,
    legalProtection: legalProtectionConfig,
    retention,
    traffic: trafficConfig,
  } = defaultConfig;
  const [values, setValues] = useState<Values>({
    annually: annuallyConfig.defaultValue,
    currency: currency.defaultValue,
    legalProtection: legalProtectionConfig.defaultValue,
    retention: retention.defaultValue,
    traffic: trafficConfig.defaultValue,
  });
  const isBlackFriday = (): boolean => {
    const blackFridayDate = new Date(2021, 10, 26, 0, 0, 0, 0);
    const today = new Date();
    return (
      blackFridayDate.getDate() === today.getDate() &&
      blackFridayDate.getMonth() === today.getMonth() &&
      blackFridayDate.getFullYear() === today.getFullYear()
    );
  };
  const isCouponApplied = (values: Values): boolean => {
    const { annually, retention } = values;
    return isBlackFriday() && annually && retention < 0.00004;
  };
  const calculateScale = (value: number) =>
    value <= 1000000 ? value : value * 10 - 9000000;
  const calculatePrice = (values: Values): number => {
    /*
    @todo: get this values from props
    if (isCustomPlan && isPriceNull && !canBuy(values)) {
      return 0;
    }
     */

    const blackFridayDiscount = 0.5;
    const { annually, currency, retention, traffic } = values;
    const monthly = Math.ceil(
      Math.max(
        defaultConfig.initialValue +
        (calculateScale(traffic) * retention * currency),
        defaultConfig.minPrice * currency,
      ),
    );
    const price = monthly * (annually ? annuallyConfig.months : 1);

    return Math.ceil(
      isCouponApplied(values) ? price * blackFridayDiscount : price,
    );
  };
  const [price, setPrice] = useState(calculatePrice(values));
  const _setValues = (newValues: Partial<Values>) => {
    setValues((values) => {
      const updatedValues = { ...values, ...newValues };
      setPrice(calculatePrice(updatedValues));

      return updatedValues;
    });
  };
  const steps = getSteps(_setValues, values, calculateScale);
  const rightBlockClasses = [classes.paper, classes.rightBlock].join(' ');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper} elevation={3}>
            <div className={classes.content}>
              {steps.map(({ content, title }, index) => (
                <div key={`step-${index}`} className={classes.block}>
                  <Typography>
                    <span className={classes.highlight}>STEP {index + 1}.</span>{' '}
                    {title}
                  </Typography>
                  {content}
                </div>
              ))}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={rightBlockClasses} elevation={3}>
            <Summary
              price={price}
              setValues={_setValues}
              values={values}
              isBlackFriday={isBlackFriday}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
