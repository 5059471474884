import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      backgroundColor: colors.alabaster,
      boxShadow: '0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
      color: colors.scorpion,
      fontSize: theme.typography.pxToRem(12),
      margin: theme.spacing(0, 0, 1),
    },
    slider: {
      marginTop: theme.spacing(3),
    },
  }),
);
