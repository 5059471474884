import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { colors } from './shared/consts';

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.radicalRed,
    },
    background: {
      default: colors.white,
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeightRegular: 300,
    fontWeightBold: 400,
  },
  zIndex: {
    tooltip: 1200,
  },
  overrides: {
    MuiIconButton: {
      root: {
        position: 'absolute',
      },
    },
  },
});
