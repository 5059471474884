import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    price: {
      alignItems: 'center',
      borderRight: `1px solid ${colors.jetStream}`,
      display: 'flex',
      paddingLeft: theme.spacing(2),
      width: '100%',
    },
    row: {
      backgroundColor: colors.white,
      borderRadius: 2,
      color: colors.scorpion,
      display: 'flex',
      height: 50,
      margin: theme.spacing(1, 0),
    },
    select: {
      '&:focus': {
        background: 'none',
      },
    },
    selectWrapper: {
      border: 'none',
      height: '100%',
      paddingLeft: theme.spacing(2),

      '&:focus, &:hover': {
        border: 'none',
        outline: 'none',
      },
    },
    switcher: {
      marginLeft: theme.spacing(-0.5),
    },
    switcherLabel: {
      fontSize: theme.typography.pxToRem(14),
    },
    title: {
      fontWeight: 'bold',
    },
    promo: {
      fontSize: 13,
      fontWeight: 'bold',
      color: colors.black,
      marginTop: 10,
    },
  }),
);
