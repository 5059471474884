import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from './shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    block: {
      marginBottom: theme.spacing(2),
    },
    content: {
      maxWidth: '90%',
    },
    highlight: {
      color: colors.brightTurquoise,
      fontWeight: 'bold',
    },
    rightBlock: {
      backgroundColor: colors.brightTurquoise,
      color: colors.white,
      height: '100%',
    },
  }),
);
