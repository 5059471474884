import React from 'react';
import { TrafficSlider, Variants } from './components';
import { defaultConfig } from './shared/consts/config.const';
import { Values, ValuesType } from './shared/types';

export const getSteps = (
  setValues: (values: Partial<Values>) => void,
  values: Values,
  calculateScale: (value: number) => number,
): { content: JSX.Element; title: string }[] => {
  const { legalProtection, retention, traffic } = defaultConfig;

  return [
    {
      content: (
        <TrafficSlider
          config={traffic}
          setValue={setValues}
          value={values.traffic}
          calculateScale={calculateScale}
        />
      ),
      title: traffic.title,
    },
    {
      content: (
        <Variants
          setValue={setValues}
          type={ValuesType.RETENTION}
          value={values.retention}
          variants={retention.variants}
        />
      ),
      title: retention.title,
    },
    {
      content: (
        <Variants
          setValue={setValues}
          type={ValuesType.LEGAL_PROTECTION}
          value={values.legalProtection}
          variants={legalProtection.variants}
        />
      ),
      title: legalProtection.title,
    },
  ];
};
