import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      backgroundColor: colors.alabaster,
      boxShadow: '0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
      fontWeight: 'bold',
    },
    buttonRow: {
      margin: theme.spacing(1, -1, 0),
    },
    button: {
      margin: theme.spacing(0, 1),
      minWidth: 'auto',
      padding: theme.spacing(0.2, 1),
      textTransform: 'inherit',

      '&:hover': {
        backgroundColor: colors.alabaster,
        boxShadow:
          '0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
      },
    },
  }),
);
