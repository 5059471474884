import { Config, Variant } from '../types';

const legalProtectionVariants: Variant[] = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];
const currencyVariants: Variant[] = [
  { label: '€', value: 1.0, name: 'eur' },
  { label: '$', value: 1.13, name: 'usd' },
  { label: 'PLN', value: 4.85, name: 'pln' },
];
const retentionVariants: (Variant & { interval: string })[] = [
  { label: '2 weeks', value: 0.000066, interval: 'P14D' },
  { label: '1 month', value: 0.000123, interval: 'P1M' },
  { label: '3 months', value: 0.00024, interval: 'P3M' },
  { label: '6 months', value: 0.00048, interval: 'P6M' },
  { label: '1 year', value: 0.00096, interval: 'P1Y' },
];

export const defaultConfig: Required<Config> = {
  minPrice: 79,
  initialValue: 66,
  annually: {
    defaultValue: false,
    label: 'Annually',
    months: 10,
  },
  contact: {
    formId: '72c4b31e-e137-4ef7-8c7c-06504eda6d73',
    portalId: 4564775,
    title: 'Request a meeting',
    url: 'https://cux.io/request-demo/',
  },
  currency: {
    variants: currencyVariants,
    defaultValue: currencyVariants[0].value as number,
    title: 'PRICE',
  },
  legalProtection: {
    defaultValue: legalProtectionVariants[0].value as boolean,
    title: 'Custom legal protection and priority support queue',
    variants: legalProtectionVariants,
  },
  retention: {
    defaultValue: retentionVariants[1].value as number,
    title: 'How long do you want us to store your data?',
    variants: retentionVariants,
  },
  traffic: {
    defaultValue: 100000,
    max: 4400000,
    min: 100000,
    step: 100000,
    title: 'What is estimated monthly traffic on your site?',
  },
};
