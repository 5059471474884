import Button from '@material-ui/core/Button';
import React, { MouseEvent } from 'react';
import { Values, ValuesType, Variant } from '../../shared/types';
import { useStyles } from './variants.styles';
import clsx from 'clsx';

type Props = {
  setValue: (values: Partial<Values>) => void;
  type: ValuesType;
  value: unknown;
  variants: Variant[];
};

export const Variants = ({ variants, value, type, setValue }: Props) => {
  const classes = useStyles();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget.dataset;

    if (!value) {
      return;
    }

    setValue({ [type]: JSON.parse(value) });
  };

  return (
    <div className={classes.buttonRow}>
      {variants.map(({ label, value: variantValue }, index) => (
        <Button
          color={variantValue === value ? 'primary' : 'default'}
          className={clsx(classes.button, {
            [classes.active]: variantValue === value,
          })}
          data-value={variantValue}
          key={`variant-${type}-${index}`}
          onClick={handleClick}
          size="small"
          variant="text"
        >
          {label}
        </Button>
      ))}
    </div>
  );
};
