import { ThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import '@openfonts/open-sans_latin-ext';
import './index.css';
import { Calculator } from './calculator.component';
import { customTheme } from './theme.util';
import * as serviceWorker from './serviceWorker';
import { Container, Grid, Typography } from '@material-ui/core';
import logo from './assets/cux-logo.svg';
const theme = createMuiTheme(customTheme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" style={{ alignItems: 'center', display: 'flex', height: '100vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img src={logo} width={80} alt="cux.io logo" />
          </Grid>
          <Grid item xs={12}>
            <Calculator />
          </Grid>
          <Grid item>
            <Typography variant="body2" paragraph>
              Pricing apply: on 3.10.2022 for projects created after 3.10.2022, 1.01.2023 for projects created before 3.10.2022
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
